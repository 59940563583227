<template>
  <div>
    <b-card>
      <!-- <div class="admin-materials-button">
        <b-button variant="warning" class="push-right" @click="openModal"><feather-icon
            icon="PlusIcon" /> {{ $t('Solicitar Materiales') }}</b-button>
      </div> -->
      <div class="align-items-center">
        <filter-swapper :trigger="selectedRows.length === 0" id="materials_swapper" :buttons="buttons"
          :controlHeightButtons="controlHeight">
          <template #slot1>
            <form-render :key="keyFormRender" 
              @send="filterForm" 
              class="mb-1" :fields="fields" 
              :buttonSend="buttonSend" 
              :form.sync="formFilter"
              v-show="!loading.firstLoad"
              containerButtonsClass="col-md-4 col-xl-3 mt-2">
                <template #buttons>
                  <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>  
                  <b-button class="search-advanced" variant="link" @click="showFilterAdvance">{{ $t('advanced-search') }}</b-button>
                </template>
                <template #extra>
                  <div class="col-md-12 mt-2" v-show="!loading.firstLoad">
                    <div class="row" style="float: right">
                      <div class="col" style="float: right">
                        <b-button @click="openModal" 
                          variant="warning" 
                          class="push-right">
                            <feather-icon icon="PlusIcon" /> {{ $t('Solicitar Materiales') }}
                        </b-button>
                        <!-- Export btn -->
                        <b-button @click="checkFiltersForExport" 
                          v-show="mySession.role === ROLES.admin || mySession.role === ROLES.superadmin"
                          class="push-right mr-1" 
                          variant="success"
                          :disabled="loading.excel">
                          <div>
                            <feather-icon v-if="!loading.excel" icon="DownloadIcon"/> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                            <span> {{ $t('Exportar diarios') }} </span>
                          </div>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
            </form-render>
          </template>
        </filter-swapper>
        <div class="font-weight-bold mb-2 collapse-count hidden" v-show="selectedRows.length > 0">
          {{ selectedRows.length }} elementos seleccionados
        </div>
      </div>
      <table-render v-show="!loading.firstLoad" :key="keyTableRender" id="materials" :rows.sync="rows" :schema="schema"
        :actions="actions" :showCheckboxes="showCheckboxes" :selectedRows.sync="selectedRows"
        :loading="loading.table">
        <template #provider_reference="scope">
            <a v-if="mySession.role === ROLES.admin" class="search-advanced text-primary" @click="openModalReference(scope.rowdata.provider_reference, scope.rowdata.id)">{{ scope.rowdata.provider_reference ? scope.rowdata.provider_reference : $t('No ingresado') }}</a>
            <span v-else class="text-primary"> {{ scope.rowdata.provider_reference ? scope.rowdata.provider_reference : $t('No ingresado') }} </span>
        </template>
        <template #status="scope">
          <!-- <div class="d-flex">
            <span class="table_dot--state" :class="[scope.rowdata.status.code === 'requested' ? 'table_dot--status-requested' : scope.rowdata.status.code === 'pending' ? 'table_dot--status-pending' : 'table_dot--status-delivered']"></span>
            <span class="ml-1"> {{ scope.rowdata.status.name }} </span>
          </div> -->
          <div class="d-flex">
            <span class="table_dot--state" :class="getStatusClass(scope.rowdata.status.code)"></span>
            <span class="ml-1">{{ scope.rowdata.status.name }}</span>
          </div>
        </template>
      </table-render>
      <pagination v-show="!loading.firstLoad" :pagination="pagination" :noDigits="true" :showSize="true" />
      <b-skeleton v-show="loading.firstLoad" height="40px" width="100%" class="mt-2 mb-2" />
      <div class="table-render-skeleton" v-show="loading.firstLoad">
        <b-skeleton-table :rows="10" :columns="schema.length || 10" :table-props="{}" />
      </div>
    </b-card>
    <modal-status ref="modalStatus" :material.sync="currentStatus" @send="saveStatusOrReference('status')"></modal-status>
    <modal-details ref="modalDetails" :selectedMaterial.sync="currentMaterial"></modal-details>
    <modal-reference ref="modalReference" :selectedMaterial.sync="currentReference" @send="saveStatusOrReference('reference')"></modal-reference>
    <modal-material ref="modalMaterial" :material.sync="currentMaterial" @send="saveMaterial"></modal-material>
    <modal-filter ref="modalFilter" :materialFilters.sync="formFilter" @send="filterAdvance"></modal-filter>
    <modal-export-materials :filters="payloadFilter"></modal-export-materials>
  </div>
</template>

<script>
import MaterialsService from './materials.service'
import { mapGetters } from 'vuex'
import ModalStatus from './ModalStatus.vue'
import ModalMaterial from './ModalMaterial.vue'
import ModalFilter from './ModalFilter.vue'
import ModalDetails from './ModalDetails.vue'
import ModalReference from './ModalReference.vue'
import * as moment from 'moment'
import ModalExportMaterials from './ModalExportMaterials.vue'


export default {
  name: 'materials',
  components: { ModalStatus, ModalMaterial, ModalFilter, ModalExportMaterials, ModalDetails, ModalReference },
  data() {
    return {
      materials: [],
      buttons: [],
      currentStatus: {},
      currentMaterial: {},
      controlHeight: { class: ' mb-2 mr-1 spacing-label-field' },
      selectedRows: [],
      schema: [],
      actions: [],
      rows: [],
      disabledBtns: {
        massiveDelete: false
      },
      showCheckboxes: false,
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      fields: [],
      initialDateRange: {
        start: moment().add(-30, 'days').toDate(),
        end: new Date()
      },
      formFilter: {},
      advancedFilters: {},
      payloadFilter: {},
      loading: {
        firstLoad: true,
        table: false,
        excel: false
      },
      customExportCarriers: [ // Listado de carriers con exportación especial
        { id: 1, name: 'Correos de Chile', code: 'CCH' },
        { id: 2, name: 'Starken', code: 'SKN' }
      ],
      exportMaterials: {
        classic: 'normal',
        custom: 'custom'
      },
      keyTableRender: 0,
      keyFormRender: 0,
      option: {
        show_checkbox: false
      },
      pagination: { page: 1, limit: 20, total: 1, loading: false },
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      currentReference: {},
      typeUpdate: {
        reference: 'reference',
        status: 'status'
      },
      materialsService: new MaterialsService(this)
    }
  },
  // Endpoints globales
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    selectedRows () {
      this.$animateCSS('.collapse-count', 'fadeIn', 'faster')
    },
    'pagination.page'() {
      this.getAllMaterials()
    },
    'pagination.limit'() {
      this.getAllMaterials()
      // if (this.pagination.page === 1) this.getAllMaterials()
    }
  },
  mounted() {
    this.buttons = [
      { 
        name: 'massiveEdition', 
        text: 'Editar Estados', 
        color: 'warning', 
        icon: 'EditIcon', 
        action: this.editMasiveStatus, 
        containerClass: 'mr-1' 
      },
      { 
        name: 'massiveDelete', 
        text: 'Eliminar solicitudes', 
        color: 'danger', 
        icon: 'TrashIcon', 
        disabled: this.disabledBtns.massiveDelete, 
        action: this.confirmMassiveDelete 
      }
    ]
    this.formFilter = {
      date_range: this.initialDateRange
    }
    this.getAllInitialData()
  },
  methods: {
    // Función para inicializar la data
    getAllInitialData() {
      if (this.mySession.id) this.setInitialData()
    },
    // Creo los campos que en la tabla, botones de interacción y input de filtrado a mostrar
    setInitialData() {
      this.schema = [
        { label: 'ID Envíame', sortable: true, key: 'id' },
        { label: 'Ref. Carrier', sortable: true, key: 'provider_reference', useSlot: true },  
        { label: 'Empresa - ID', sortable: true, key: 'company.company_name_id' },
        { label: 'Courier', sortable: true, key: 'provider.name' },
        { label: 'Dirección', sortable: true, key: 'warehouse.address.full_address' },
        { label: 'Estado', sortable: true, key: 'status', useSlot: true },
        { label: 'Contacto', sortable: true, key: 'contact_name_phone' },
        { label: 'Fecha de solicitud', sortable: true, key: 'created_at' },
        { label: 'Acciones', key: 'actions', class: ['text-center'] }
      ]
      // Objeto con condiciones segun el rol para activar campos(acciones/checkboxs)
      this.option = this.materialsService.conditionForRolFields(this.mySession.role)
      // if (!!this.option.show_column_provider) this.schema.splice(this.selectionIndex('Carrier'), 1, { label: 'Proovedor', sortable: true, key: 'provider.text' })

      if (!!this.option.show_actions) {
        this.actions = [
          { action: id => this.showRequest(id), icon: 'ArchiveIcon', color: 'primary', text: 'Ver la solicitud' },
          { action: id => this.openStatus(id), icon: 'Edit2Icon', color: 'primary', text: 'Cambiar estado' },
          { action: id => this.confirmDeleteMaterial(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar solicitud' }
        ]
        this.showCheckboxes = true
      } else {
        this.actions = [
          { action: id => this.showRequest(id), icon: 'ArchiveIcon', color: 'warning', text: 'Ver la solicitud' }
        ]
      }

      if (!this.option.show_company_table) this.schema = this.materialsService.excludeTableFields(['company.company_name_id'], this.schema)

      this.fields = [
        {
          fieldType: 'FieldInput', 
          type: 'text', 
          name: 'id_ref', 
          label: 'ID Envíame/Ref. Courier', 
          containerClass: 'col-sm-12 col-md-2',
          clearable: true
        },
        {
          fieldType: 'FieldDatepicker', 
          name: 'date_range', 
          label: 'Fecha de creación', 
          range: true, 
          containerClass: 'col-sm-12 col-md-3' 
        }
      ]
      this.filterList(this.formFilter)
    },
    // Función para solicitar todos los materiales 
    getAllMaterials() {
      // Parametros a pasar 
      const queryParams = {
        ...this.payloadFilter,
        page: this.pagination.page,
        rows: this.pagination.limit
      }

      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) queryParams.company_id = this.mySession.shipper.id
      if (this.mySession.role === this.ROLES.marketplace) queryParams.organization_id = this.mySession.organization.id
      
      this.loading.table = true
      this.materialsService.callService('getMaterials', queryParams)
        .then(resp => {
          this.rows = resp.data
          this.rows.forEach(el => {
            el.contact_name_phone = `${el.contact.name} - ${el.contact.phone}`
            el.created_at = this.$moment(el.created_at).format('DD/MM/YYYY')
            el.company.company_name_id = `${el.company.name} - ${el.company.id}`
          })
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading.firstLoad = false
          this.loading.table = false
        })
    },
    // Buscamos el indice de un campo segun su nombre
    selectionIndex(name) {
      return this.schema.findIndex(el => el.label === name)
    },
    /**
     * Función para guardar el cambio de estado o la referencia courier
     * @param {String} update Indica si se actualizo la ref o el estado de una solicitud
     */
    saveStatusOrReference(update) {
      let msg = ''
      if (update === this.typeUpdate.status) {
        msg = 'El cambio de estado se realizó correctamente'
        this.$bvModal.hide('modalStatus')
      } else if (update === this.typeUpdate.reference) {
        msg = 'El cambio de referencia se realizó correctamente'
        this.$bvModal.hide('modalReference')
      }
      this.$success(msg)
      this.selectedRows = []
      this.filterList({...this.advancedFilters, ...this.formFilter})
    },
    // Función que llama a addMaterialsTable y cierra el modal
    saveMaterial(data) {
      const { form } = data
      const params = {
        company_id: form.company_id
      }
      this.$refs.modalMaterial.loading.btn = true
      this.materialsService.callService('createMaterial', form, params)
        .then(resp => {
          this.$success('Solicitud creada correctamente')
          this.$bvModal.hide('modalMaterial')
          this.$refs.modalMaterial.loading.btn = false
          this.getAllMaterials()
        })
        .catch(err => this.$alert(this.$t('Ha ocurrido un error al crear la solicitud, intente nuevamente.')))
        .finally(() => {
          this.$refs.modalMaterial.loading.btn = false
        })
    },
    // Función para abrir el modal de materiales solicitados(modalMaterial) es el mismo modal pero condicionado para cada caso
    showRequest(_id) {
      this.currentMaterial = this.rows.filter(({ id }) => id === _id)[0]
      this.$bvModal.show('modalDetails')
    },
    // Función para abrir el modal de solicitud de materiales(modalMaterial) es el mismo modal pero condicionado para cada caso
    openModal() {
      this.currentMaterial = {}
      this.$bvModal.show('modalMaterial')
    },
    // Función para abrir el modal de Edición individual de estado(modalStatus) es el mismo modal pero condicionado para cada caso
    openStatus(_id) {
      this.currentStatus = this.rows.filter(({ id }) => id === _id)[0]
      this.currentStatus.status.text = this.currentStatus.status.name
      this.$bvModal.show('modalStatus')
    },
    // Función para abrir el modal de Edición masiva de estados(modalStatus) es el mismo modal pero condicionado para cada caso
    editMasiveStatus() {
      this.currentStatus = this.selectedRows.map((id) => {
        return this.rows.filter(item => id === item.id)[0]
      })
      this.$bvModal.show('modalStatus')
    },
    /**
     * Función para confirmar eliminación de solicitudes masivas
     */
    confirmMassiveDelete () {
      this.$yesno(this.$t('msg-confirm-massive-delete'), () => this.deleteMaterials(this.selectedRows))
    },
    /**
     * Función para confirmar la eliminación de una solicitude de materiales
     * @param {Number} id id de la solicitud a eliminar
     */
    confirmDeleteMaterial (id) {
      this.$yesno(this.$t('msg-confirm-delete'), () => this.deleteMaterials([id]))
    },
    /**
     * Función para eliminar solicitudes de materiales
     * @param {Array} material_ids Arreglo con las id's de soliocitudes a eliminar
     */
    deleteMaterials (material_ids) {
      const { first_name, last_name, email, role } = this.mySession
      const queryParams = {
        user: {
          name: `${first_name} ${last_name}`,
          email,
          role
        },
        material_ids
      }
      this.disabledBtns.massiveDelete = true
      this.materialsService.callService('deleteMaterials', queryParams, null)
        .then(resp => {
          this.selectedRows = []
          this.filterList({...this.advancedFilters, ...this.formFilter})
          this.$success(this.$t(resp.data.message))
        })
        .catch(err => {
          this.$alert(this.$t(err))
        })
        .finally(() => {
          this.disabledBtns.massiveDelete = false
        })
    },
    /**
     * Función que en base a los filtros aplicados seteara un parametro para la descarga
     */
    checkFiltersForExport () {
      let datesAreSame = false
      let isCustomCarrier = false
      const {date_from, date_end, carrier_code} = this.payloadFilter
      // Primero se verifica la igualdad en la fecha y el id del carrier
      if (date_from === date_end) datesAreSame = true
      if (carrier_code) isCustomCarrier = this.customExportCarriers.find(carrier => carrier.code === carrier_code)
      if (isCustomCarrier && datesAreSame) this.$bvModal.show('modalExportMaterials')
      else this.downloadExcel()
    },
    /**
     * Función para exportar materiales
     */
    downloadExcel() {
      this.loading.excel = true
      this.materialsService.callService('exportMaterials', this.payloadFilter, null)
        .then(resp => {
          if (resp.data) window.open(resp.data, '_blank')
          else this.$alert(this.$t('No se encontraron solicitudes de materiales para exportar'))
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(() => {
          this.loading.excel = false
        })
    },
    // Función para abril modal de filtros avanzados
    showFilterAdvance() {
      this.currentMaterial = {}
      this.$bvModal.show('modalFilter')
    },
    /**
     * Función que abre el modal para actualizar las ref. courier
     */
    openModalReference (reference, material_id) {
      this.currentReference = { reference, material_id }
      this.$bvModal.show('modalReference')
    },
    /**
     * Función para filtrar la tabla de acuerdo a todos los filtros disponibles
     */
    filterList(data) {

      this.payloadFilter = {}
      if (data.country) this.payloadFilter.country_id = data.country.id
      if (data.id_ref) this.payloadFilter.material_identifier = data.id_ref
      if (data.date_range) {
        this.payloadFilter.date_from = this.$options.filters.jsDateToFormat(data.date_range.start, 'yyyy-MM-dd')
        this.payloadFilter.date_end = this.$options.filters.jsDateToFormat(data.date_range.end, 'yyyy-MM-dd')
      }
      if (data.organization) this.payloadFilter.organization_id = data.organization.id
      if (data.shipper) this.payloadFilter.company_id = data.shipper.id
      if (data.carrier) this.payloadFilter.carrier_code = data.carrier.code
      if (data.status?.length > 0) this.payloadFilter.status_code_in = data.status.map(state => state.id).toString()
      if (this.pagination.page !== 1) this.pagination.page = 1

      this.getAllMaterials()
    },
    /**
     * Función que se ejecuta al dar click en el botón de filtrar
     * del modal de filtros avanzados
     */
    filterAdvance(data) {
      this.$bvModal.hide('modalFilter')
      this.advancedFilters = data
      this.filterList(data)
    },
    filterForm (formView) {
      this.filterList({...this.advancedFilters, ...formView})
    },
    // Limpio todos lo filtros
    cleanFilter() {
      this.formFilter = {
        date_range: this.initialDateRange
      }
      this.keyFormRender++
      this.filterList(this.formFilter)
    },
    getStatusClass(statusCode) {
      const statusClassMap = {
        requested: 'table_dot--status-requested',
        pending: 'table_dot--status-pending',
        delivered: 'table_dot--status-delivered',
        dismissed: 'table_dot--status-dismissed'
      }
      return statusClassMap[statusCode] || 'table_dot--status-delivered'
    }
  }

}
</script>
<style lang='scss'>
.admin-materials-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}

@media (min-width: 1200px) {
  .col-new {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.search-advanced {
    text-decoration: underline !important;
}

.table_dot--state{
  position: relative;
  display: inline-block;
  top: 3px;
  height: 15px;
  width: 15px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.table_dot--status-requested {
  background-color:#8EE4FF;
}

.table_dot--status-pending {
  background-color:#F9A75C;
}

.table_dot--status-delivered {
  background-color: #55D6A7;
}
.table_dot--status-dismissed {
  background-color: #da4a4f;
}

</style>